import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { io } from 'socket.io-client';
import './BoredGrid.css';

const TOTAL_CHECKBOXES = 900; // 30x30
const MAX_COLUMNS = 60; // Maximum number of columns
const DOMAIN = 'https://clickcheck.xyz';
const API_URL = `${DOMAIN}/api`;

const BoredGrid = () => {
  const [checkboxes, setCheckboxes] = useState(Array(TOTAL_CHECKBOXES).fill(false));
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const gridDimensions = useMemo(() => {
    const checkboxSize = 30; // Size of each checkbox in pixels
    const gap = 5; // Gap between checkboxes in pixels
    const maxGridWidth = MAX_COLUMNS * (checkboxSize + gap) - gap;
    const availableWidth = Math.min(windowWidth - 40, maxGridWidth); // 20px padding on each side
    const columns = Math.min(Math.floor((availableWidth + gap) / (checkboxSize + gap)), MAX_COLUMNS);
    const rows = Math.ceil(TOTAL_CHECKBOXES / columns);
    return { columns, rows };
  }, [windowWidth]);

  const fetchBoredCheckboxes = useCallback(async () => {
    try {
      const response = await fetch(`${API_URL}/bored-checkboxes`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (data && Array.isArray(data)) {
        const fetchedCheckboxes = data.map(checkbox => checkbox.checked);
        setCheckboxes(fetchedCheckboxes);
      } else {
        throw new Error('Invalid response format');
      }
    } catch (err) {
      console.error('Error fetching bored checkboxes:', err);
      // Keep the default false states for all checkboxes
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchBoredCheckboxes();

    const socket = io(DOMAIN, {
      path: '/socket.io/',
      transports: ['websocket', 'polling']
    });

    socket.on('connect', () => {
      console.log('Connected to Socket.IO for BoredGrid');
    });

    socket.on('boredCheckboxUpdated', (updatedCheckbox) => {
      console.log('Bored checkbox updated:', updatedCheckbox);
      setCheckboxes(prev => {
        const newCheckboxes = [...prev];
        newCheckboxes[updatedCheckbox.id - 1] = updatedCheckbox.checked;
        return newCheckboxes;
      });
    });

    socket.on('connect_error', (error) => {
      console.error('Socket.IO connection error for BoredGrid:', error);
    });

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      socket.disconnect();
      window.removeEventListener('resize', handleResize);
    };
  }, [fetchBoredCheckboxes]);

  const handleCheckboxChange = async (index) => {
    try {
      const response = await fetch(`${API_URL}/bored-checkboxes/${index + 1}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          checked: !checkboxes[index]
        }),
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      if (data && typeof data.checked === 'boolean') {
        setCheckboxes(prev => {
          const newCheckboxes = [...prev];
          newCheckboxes[index] = data.checked;
          return newCheckboxes;
        });
      } else {
        throw new Error('Invalid response format');
      }
    } catch (err) {
      console.error('Error updating bored checkbox:', err);
      setError('Failed to update checkbox on the server. The display may be out of sync.');
      // Optimistically update the checkbox state
      setCheckboxes(prev => {
        const newCheckboxes = [...prev];
        newCheckboxes[index] = !newCheckboxes[index];
        return newCheckboxes;
      });
    }
  };

  return (
    <div className="bored-grid-container">
      <h2 className="bored-grid-title">If you are bored of waiting for transactions</h2>
      {loading && <div className="bored-grid-loading">Loading bored grid...</div>}
      {error && <div className="bored-grid-error">{error}</div>}
      <div 
        className="bored-grid" 
        style={{
          gridTemplateColumns: `repeat(${gridDimensions.columns}, 30px)`,
          gridTemplateRows: `repeat(${gridDimensions.rows}, 30px)`,
        }}
      >
        {checkboxes.map((isChecked, index) => (
          <div key={index} className="bored-checkbox-item">
            <label className="bored-checkbox-label">
              <input
                type="checkbox"
                checked={isChecked}
                onChange={() => handleCheckboxChange(index)}
                className="bored-custom-checkbox"
                aria-label={`Bored Checkbox ${index + 1}`}
                disabled={loading}
              />
              <span className="bored-checkmark">
                <span className="bored-x-mark"></span>
              </span>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BoredGrid;