import { ethers } from 'ethers';
import contracts from './contracts.json';

const ABI = [
  {
    "inputs": [],
    "name": "CheckBox",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  }
];

const REQUIRED_CHAIN_ID = 167000; // Taiko network chain ID

class SendTransaction {
  constructor() {
    this.provider = null;
  }

  setProvider(provider) {
    this.provider = provider;
  }

  async switchNetwork() {
    if (!this.provider) {
      throw new Error('Provider not set. Please connect your wallet first.');
    }

    try {
      await this.provider.send('wallet_switchEthereumChain', [{ chainId: ethers.utils.hexValue(REQUIRED_CHAIN_ID) }]);
    } catch (switchError) {
      // This error code indicates that the chain has not been added to the wallet
      if (switchError.code === 4902) {
        try {
          await this.provider.send('wallet_addEthereumChain', [
            {
              chainId: ethers.utils.hexValue(REQUIRED_CHAIN_ID),
              chainName: 'Taiko',
              nativeCurrency: {
                name: 'ETH',
                symbol: 'ETH',
                decimals: 18,
              },
              rpcUrls: ['https://rpc.mainnet.taiko.xyz'],
              blockExplorerUrls: ['https://taikoscan.io/'],
            },
          ]);
        } catch (addError) {
          throw new Error('Failed to add the Taiko network.');
        }
      } else {
        throw new Error('Failed to switch to the Taiko network.');
      }
    }
  }

  async sendToggleTransaction(boxNumber) {
    if (!this.provider) {
      throw new Error('Provider not set. Please connect your wallet first.');
    }

    const contractAddress = contracts[boxNumber];
    if (!contractAddress) {
      throw new Error(`No contract address found for box ${boxNumber}`);
    }

    const signer = this.provider.getSigner();
    const contract = new ethers.Contract(contractAddress, ABI, signer);

    try {
      const transaction = await contract.CheckBox();
      console.log(`Transaction sent for box ${boxNumber}:`, transaction.hash);
      
      const receipt = await transaction.wait();
      console.log(`Transaction confirmed for box ${boxNumber}:`, receipt.transactionHash);
      
      return receipt;
    } catch (error) {
      console.error(`Error sending transaction for box ${boxNumber}:`, error);
      throw error;
    }
  }
}

export default SendTransaction;