import React, { useEffect, useState, useCallback } from 'react';
import { ethers } from 'ethers';
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
import { io } from 'socket.io-client';
import SendTransaction from './SendTransaction';
import './CheckboxGrid.css';
import BoredGrid from './BoredGrid';

const REQUIRED_CHAIN_ID = 167000;
const DOMAIN = 'https://clickcheck.xyz';
const API_URL = `${DOMAIN}/api`;
const GRID_SIZE = 6;
const TOTAL_CHECKBOXES = GRID_SIZE * GRID_SIZE;
const RECONNECT_INTERVAL = 2000;

const generateId = () => Math.random().toString(36).substr(2, 9);

const CheckboxGrid = () => {
  const [checkboxes, setCheckboxes] = useState(Array(TOTAL_CHECKBOXES).fill({ checked: false }));
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [darkMode, setDarkMode] = useState(true);
  const [address, setAddress] = useState('');
  const [chainId, setChainId] = useState(null);
  const [sendTransaction] = useState(() => new SendTransaction());
  const [stats, setStats] = useState({ lastCompletedBy: 'Not yet', totalCompletions: 0 });
  const [socket, setSocket] = useState(null);
  const [web3Modal, setWeb3Modal] = useState(null);

  // New State Variables for Block Info
  const [currentBlock, setCurrentBlock] = useState(null);
  const [lastBlockTime, setLastBlockTime] = useState(null);
  const [secondsSinceLastBlock, setSecondsSinceLastBlock] = useState(0);

  const fetchStats = useCallback(async () => {
    try {
      console.log('Fetching stats from:', `${API_URL}/last_data`);
      const response = await fetch(`${API_URL}/last_data`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log('Stats response:', data);
      const { lastCompletedBy, totalCompletions } = data;
      setStats({
        lastCompletedBy: lastCompletedBy ? `${lastCompletedBy.slice(0, 4)}...${lastCompletedBy.slice(-4)}` : 'Not yet',
        totalCompletions: totalCompletions || 0
      });
    } catch (err) {
      console.error('Error fetching stats:', err);
      setStats({ lastCompletedBy: 'Not yet', totalCompletions: 0 });
      setTimeout(fetchStats, RECONNECT_INTERVAL);
    }
  }, []);

  const connectSocket = useCallback(() => {
    const newSocket = io(DOMAIN, {
      path: '/socket.io/',
      transports: ['websocket', 'polling'],
      auth: {
        sessionID: generateId(),
      },
      reconnection: true,
      reconnectionAttempts: Infinity,
      reconnectionDelay: RECONNECT_INTERVAL,
    });

    newSocket.on('connect', () => {
      console.log('Connected to Socket.IO');
    });

    newSocket.on('connect_error', (error) => {
      console.error('Socket.IO connection error:', error);
    });

    newSocket.on('disconnect', (reason) => {
      console.log('Disconnected from Socket.IO:', reason);
      if (reason === 'io server disconnect') {
        setTimeout(() => newSocket.connect(), RECONNECT_INTERVAL);
      }
    });

    newSocket.on('checkboxUpdated', (updatedCheckbox) => {
      console.log('Checkbox updated:', updatedCheckbox);
      setCheckboxes(prev => prev.map(cb => 
        cb.id === updatedCheckbox.id ? { ...cb, checked: updatedCheckbox.checked } : cb
      ));
    });

    newSocket.on('allCheckboxesCompleted', () => {
      console.log('All checkboxes completed');
      fetchStats();
    });

    setSocket(newSocket);

    return newSocket;
  }, [fetchStats]);

  const fetchCheckboxes = useCallback(async () => {
    try {
      console.log('Fetching checkboxes from:', `${API_URL}/checkboxes`);
      const response = await fetch(`${API_URL}/checkboxes`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log('Checkboxes response:', data);
      if (data && Array.isArray(data)) {
        const fetchedCheckboxes = data.slice(0, TOTAL_CHECKBOXES);
        const paddedCheckboxes = [...fetchedCheckboxes, ...Array(TOTAL_CHECKBOXES - fetchedCheckboxes.length).fill().map((_, i) => ({
          id: fetchedCheckboxes.length + i + 1,
          checked: false
        }))];
        setCheckboxes(paddedCheckboxes);
        setLoading(false);
      } else {
        throw new Error('Invalid response format');
      }
    } catch (err) {
      console.error('Error fetching checkboxes:', err);
      setError('Failed to load checkboxes. Please try again later.');
      setLoading(false);
      setTimeout(fetchCheckboxes, RECONNECT_INTERVAL);
    }
  }, []);

  const initializeWeb3Modal = useCallback(() => {
    const newWeb3Modal = new Web3Modal({
      network: "taiko",
      cacheProvider: true,
      providerOptions: {
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            rpc: {
              [REQUIRED_CHAIN_ID]: 'https://rpc.mainnet.taiko.xyz'
            },
          }
        },
        coinbasewallet: {
          package: CoinbaseWalletSDK,
          options: {
            appName: "ClickAndCheck",
            rpc: {
              [REQUIRED_CHAIN_ID]: 'https://rpc.mainnet.taiko.xyz'
            },
            chainId: REQUIRED_CHAIN_ID
          }
        },
      }
    });
    setWeb3Modal(newWeb3Modal);
    return newWeb3Modal;
  }, []);

  const connectWallet = useCallback(async (web3ModalInstance) => {
    try {
      const instance = await web3ModalInstance.connect();
      const provider = new ethers.providers.Web3Provider(instance);
      const signer = provider.getSigner();
      const address = await signer.getAddress();
      const network = await provider.getNetwork();

      setAddress(address);
      setChainId(network.chainId);
      sendTransaction.setProvider(provider);

      instance.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setAddress(accounts[0]);
        } else {
          disconnectWallet();
        }
      });

      instance.on("chainChanged", (chainId) => {
        setChainId(parseInt(chainId, 16));
        window.location.reload();
      });

      instance.on("disconnect", () => {
        disconnectWallet();
      });
    } catch (error) {
      console.error("Could not connect to wallet", error);
      setError("Failed to connect wallet. Please try again.");
    }
  }, [sendTransaction]);

  useEffect(() => {
    const newWeb3Modal = initializeWeb3Modal();
    const newSocket = connectSocket();
    fetchCheckboxes();
    fetchStats();

    // Check if the user was previously connected
    if (newWeb3Modal.cachedProvider) {
      connectWallet(newWeb3Modal);
    }

    return () => {
      if (newSocket) newSocket.disconnect();
    };
  }, [initializeWeb3Modal, connectSocket, fetchCheckboxes, fetchStats, connectWallet]);

  const handleConnectWallet = async () => {
    if (web3Modal) {
      await connectWallet(web3Modal);
    }
  };

  const disconnectWallet = async () => {
    if (web3Modal) {
      await web3Modal.clearCachedProvider();
      setAddress('');
      setChainId(null);
      sendTransaction.setProvider(null);
    }
  };

  const handleCheckboxChange = async (id) => {
    if (!sendTransaction || !address) {
      console.error('SendTransaction not initialized, or wallet not connected');
      return;
    }

    try {
      if (chainId !== REQUIRED_CHAIN_ID) {
        await sendTransaction.switchNetwork();
        setChainId(REQUIRED_CHAIN_ID);
      }
      await sendTransaction.sendToggleTransaction(id);
    } catch (error) {
      console.error('Error toggling checkbox:', error);
      setError('Failed to toggle checkbox. Please try again.');
    }
  };

  const toggleDarkMode = () => {
    setDarkMode(prevMode => !prevMode);
  };

  const switchNetwork = async () => {
    try {
      await sendTransaction.switchNetwork();
      const provider = sendTransaction.provider;
      const network = await provider.getNetwork();
      setChainId(network.chainId);
    } catch (error) {
      console.error('Error switching network:', error);
      setError('Failed to switch network. Please try again.');
    }
  };

  // New useEffect for Blockchain Block Tracking
  useEffect(() => {
    const rpcUrl = 'https://rpc.mainnet.taiko.xyz';
    const provider = new ethers.providers.JsonRpcProvider(rpcUrl);

    const handleNewBlock = (blockNumber) => {
      setCurrentBlock(blockNumber);
      setLastBlockTime(Date.now());
    };

    provider.on('block', handleNewBlock);

    return () => {
      provider.off('block', handleNewBlock);
      // Removed provider.destroy() as it does not exist
    };
  }, []);

  // New useEffect for Timer
  useEffect(() => {
    const interval = setInterval(() => {
      if (lastBlockTime) {
        const seconds = Math.floor((Date.now() - lastBlockTime) / 1000);
        setSecondsSinceLastBlock(seconds);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [lastBlockTime]);

  return (
    <div className={`checkbox-grid-container ${darkMode ? 'dark-mode' : 'light-mode'}`}>
      <div className="header">
        <h1 className="title">Real-Time Checkbox Grid</h1>
        <div className="header-right">
          <button className="toggle-button" onClick={toggleDarkMode}>
            {darkMode ? '🌙 Light Mode' : '☀️ Dark Mode'}
          </button>

          {/* New Block Info Display */}
          {currentBlock !== null && (
            <span className="block-info">
              Block: {currentBlock} | {secondsSinceLastBlock} sec ago
            </span>
          )}

          {!address ? (
            <button className="connect-button" onClick={handleConnectWallet}>
              Connect Wallet
            </button>
          ) : chainId !== REQUIRED_CHAIN_ID ? (
            <button className="network-button wrong-network" onClick={switchNetwork}>
              Switch to Taiko Network
            </button>
          ) : (
            <span className="address-display" onClick={disconnectWallet}>
              {`${address.substring(0, 6)}...${address.substring(address.length - 4)}`}
            </span>
          )}
        </div>
      </div>
      <div className="stats-container">
        <p>Total Completions: {stats.totalCompletions}</p>
        <p>Last Completed By: {stats.lastCompletedBy}</p>
      </div>
      <div className="checkbox-grid">
        {checkboxes.map((checkbox, index) => (
          <div key={index} className="checkbox-item">
            <label className="checkbox-label">
              <input
                type="checkbox"
                checked={checkbox.checked}
                onChange={() => handleCheckboxChange(checkbox.id)}
                className="custom-checkbox"
                aria-label={`Checkbox ${checkbox.id}`}
                disabled={loading}
              />
              <span className={`checkmark ${loading ? 'loading' : ''}`}>
                <span className="x-mark"></span>
              </span>
            </label>
          </div>
        ))}
      </div>
      {error && <div className="status-message error">{error}</div>}
      <BoredGrid />
    </div>
  );
};

export default CheckboxGrid;
